import React from "react";
import { Styling } from "./styled.js";
import { Link } from "gatsby";

export default function Page3Form({ data }) {
  return (
    <Styling>
      {/* <Link to={"/campaign/campaign-test-4"}>Thank you page</Link> */}
      <div className="iframe-wrapper">
        {/* Jr3BnIpT is Mike Test Form */}
        {/* <iframe id="typeform-full" width="100%" height="100%" frameBorder="0" src="https://8msvm8hayy6.typeform.com/to/Jr3BnIpT"></iframe> */}
        {/* XqsMKWWd is Correct Live Form */}
        <iframe id="typeform-full" width="100%" height="100%" frameBorder="0" src="https://8msvm8hayy6.typeform.com/to/XqsMKWWd"></iframe>
      </div>
    </Styling>
  );
}
